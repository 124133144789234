<template>
    <div class="form">
        <div class="background background_blue background_broker background_broker_q">
            <div class="background__image background__image_broker"></div>
        </div>

        <div class="wrapper" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
            <div class="content">
                <div class="content__container brokersBenefits">
                    <TextBlock :id="27" />

                    <a class="button how_it_works" :href="$store.state.params ? $store.state.params.how_it_works : '#'" target="_blank">
                        <div class="button_icon"><span class="icon-play"></span></div>
                        {{ "b_how_it_works" | localize }}
                    </a>
                </div>
            </div>
        </div>
        <PopupText class="popup_lawyer" />
    </div>
</template>

<script>
import PopupText from '@/components/PopupText.vue'
import TextBlock from '@/components/Text.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Broker_Benefits',
  mixins: [commonMixin],
  components: { PopupText, TextBlock },
  created () { this.$store.commit('togglePopupText', { id: 22, withTitle: false, type: 'popup_info' }) }
}
</script>